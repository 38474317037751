.space {
    padding: 1.5rem;
}

.submitBtn {
    margin-right: 1.5rem;
}

.table {
    padding: 1rem 1.5rem;
}

.modelBtn {
    align-items: flex-end;
    margin-bottom: 0;
}

.blueText {
    color: #1890ff !important;
}

.content {
    padding: 1rem 0;
}

.search {
    font-weight: 400;

    :global .ant-input {
        height: auto;
    }
}

.tableHeader {
    padding: .75rem 0;
}

.button {
    margin-right: 1rem;
}

.text {
    margin: 1rem 0 0;
}

.model_card_row {
    margin-bottom: 1rem;
}

.model_card_head {
    min-height: auto;
}

.row {
    margin-bottom: .6rem;
}

.icon {
    padding: 0;
    margin: 0;
}

.ListItemWrap {
    display: flex;
    justify-content: space-between;
    align-items: start;
    justify-self: end;
    flex-wrap: wrap;
    width: 100%;
}

.paragraph {
    padding: 0;
    margin: 0 !important;
}

.iconAlt {
    font-size: 1.3rem;
}

.iconError {
    @extend .iconAlt;
    color: #f5222d;
}

.iconSuccess {
    @extend .iconAlt;
    color: #52c41a;
}

.blackBorder {
    border-color: rgb(0, 0, 0);
}

.font_weight_normal {
    font-weight: 400;
}

.bgSecondary {
    background-color: lightskyblue;
    border-radius: 2px;
}

.baseLineWrap {
    display: flex;
    align-items: baseline;
    width: 100%;
}