.chat-bubble-container {
    max-width: 70%;
    transform: translateZ(0);
}

.chat-bubble-container-assistant {
    @extend .chat-bubble-container;
    margin-left: 0;
}

.chat-bubble-container-user {
    @extend .chat-bubble-container;
    margin-left: auto;
}

.chat-bubble {
    border-radius: 5px;
    padding: 8px;
    background-color: #f0f0f0;
}

.chat-bubble-assistant {
    @extend .chat-bubble;
}

.chat-bubble-user {
    @extend .chat-bubble;
    background-color: rgba(#1890ff, .75);
}