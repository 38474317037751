.icon {
    cursor: pointer;
    font-size: 2rem;
}

.iconAlt {
    cursor: pointer;
    background-color: #C8CDD5;
    border-radius: 8px;
    width: 1.7rem;
    height: 1.7rem;
    padding: 5px;

    &:hover {
        background-color: rgba(#091945, .8);
        color: white;
    }
}

.iconBase {
    fill: #bfbfbf;
    cursor: pointer;
}