.image {
    width: 100%;
    height: 500px;
    object-fit: contain;
    object-position: center;
}

.link {
    text-decoration: underline;
}

.imageIcon {
  width: 100%;
  height: 120px;
  object-fit: contain;
  object-position: center;
  padding: .5rem 0;
}