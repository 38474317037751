.bigText {
    font-size: 1.2rem;
    font-weight: 400;
    color: #000;
    padding: 1rem .3rem;
}

.heading {
    color: #000 !important;
    font-weight: 500 !important;
}

.subHeading {
    color: #474b52 !important;
    font-weight: 400 !important;
    font-size: .8rem !important;
    padding-top: .5rem;
    margin: 0 !important;
}

.amount {
    color: #091945 !important;
    font-weight: 500 !important;
    margin: 0;
    font-size: 1rem;
}

.footer {
    color: #474b52 !important;
    font-weight: 500 !important;
    font-size: .8rem !important;
}