.imageItem {
  width: 100%;
  height: 120px;
  object-fit: cover;
  object-position: center;
}

.imageIcon {
  width: 100%;
  height: 120px;
  object-fit: contain;
  object-position: center;
  padding: 2rem;
}

.icon {
  font-size: medium;
  margin-right: 0 !important;
}