.wrapper {
    display: flex;
    align-items: center;
}

$stripe: #9254de;
$paypal: #4096ff;
$klarna: #f759ab;

.icon {
    font-size: 1.6rem;
}

.icon_paypal {
    @extend .icon;
    color: $paypal;
}

.icon_klarna {
    @extend .icon;
    color: $klarna;
}

.icon_stripe {
    @extend .icon;
    color: $stripe;
}