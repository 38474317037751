.password {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 6px;
}

.animationImage {
    z-index: 1;
    position: relative;
    width: 500px !important;
    top: 25%;
    right: 70%;
}