@use 'Fonts.scss';
@use "Global.scss";

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Global.$fontSecondary;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

a {
    color: inherit;
    text-decoration: none;
    transition: all ease-in-out 0.3s;
    -moz-transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
}

.cursor-pointer {
    cursor: pointer;
}

.bg-white {
    background-color: white;
}

.rounded {
    border-radius: 5px;
}

.text-primary {
    color: #086BDE;
}

// react-html5-camera-photo
.react-html5-camera-photo>video {
    width: 1000px !important;
}

.react-html5-camera-photo>img {
    width: 1000px !important;
}

.fs-1 {
    font-size: 4rem;
}

.fs-2{
    font-size: 3rem;
}

.fs-3 {
    font-size: 2rem;
}

.fs-4 {
    font-size: 1.5rem;
}

.fs-5 {
    font-size: 1.25rem;
}

.fs-6 {
    font-size: 1rem;
}

// antd
.site-page-header {
    border: 1px solid rgb(235, 237, 240);
    background-color: white;
}

.ant-card-head-title {
    padding: .5rem 0;
}

.ant-card-head-wrapper {
    min-height: auto;
}

.ant-card-head {
    min-height: auto;
    font-size: 14px;
}

.ant-descriptions {
    margin-bottom: 1rem;
    margin-top: 1rem;

    .ant-descriptions-title {
        margin-bottom: 0;
    }
}

.ant-input,
.ant-btn,
.ant-radio-group .ant-radio-button-wrapper {
    font-weight: normal;
}

.ant-list-header {
    font-weight: 500;
}

// ant-table-editable-cell
.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

// utilites
.space-between {
    justify-content: space-between;
    width: 94%;
}

.px-0 {
    padding-left: 0;
    padding-right: 0;
}

.fs-12 {
    font-size: 12px;
}

.w-100 {
    width: 100%;
}

.justify-between {
    justify-content: space-between !important
}

.small {
    font-size: 12px;
}

.extra-small {
    font-size: 10px;
}

.text-wrap {
    white-space: normal;
}

// bootstrap border
.border {
    border: 1px solid #dee2e6 !important;
}

.border-top {
    border-top: 1px solid #dee2e6 !important;
}

.border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
    border-left: 1px solid #dee2e6 !important;
}

.border-right {
    border-right: 1px solid #dee2e6 !important;
}

.border-0 {
    border: 0 !important;
}

.border-bottom-dashed {
    border-bottom: 1px dashed #dee2e6 !important;
}

// table strike through
.strike-through-row>td {
    text-decoration: line-through;
}

// visibility
.visible {
  visibility: visible;
}
.invisible {
  visibility: hidden;
}