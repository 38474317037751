.space {
    padding: 1.5rem;
}

.submitBtn {
    margin-right: 1.5rem;
}

.table {
    padding: 1rem 1.5rem;
}

.modelBtn {
    align-items: flex-end;
    margin-bottom: 0;
}

.blueText {
    color: #1890ff !important;
}

.content {
    padding: 1rem 0;
}

.search {
    padding-right: 3rem;

    :global .ant-input {
        height: auto;
    }
}

.tableHeader {
    padding: 0 0 1rem 0;
}

.button {
    margin-right: 1rem;
}

.text {
    margin: 1rem 0 0;
}

.close-btn {
    .ant-btn svg{
        margin: 0px !important;
    }
}