.animation {
    position: relative;
    top: 0;
    left: 50%;
    width: 235px;
    min-width: 235px;
    height: 330px;
}

.circle {
    border: solid 1px #d4d8db;
    border-radius: 50%;
    position: absolute;
    z-index: 0;
}

.animation__circle-left {
    width: 276px;
    height: 276px;
    top: 0;
    left: -45px;
    animation: rotate-left 10s linear infinite normal;
}

.animation__circle-right {
    width: 244px;
    height: 244px;
    top: -21px;
    left: 70px;
    animation: rotate-right 5s linear infinite normal;
}

.animation__circle-bottom {
    width: 230px;
    height: 230px;
    top: 78px;
    left: 52px;
    animation: rotate-left 8s linear infinite normal;
}

.animation__circle-mini {
    border-radius: 50%;
    position: absolute;
    z-index: -1;
}

.mini-left-1 {
    width: 10px;
    height: 10px;
    background-color: #c6cdd7;
    top: 0;
    left: 95px;
}

.mini-left-2 {
    width: 20px;
    height: 20px;
    background-color: #4e8acc;
    left: 0;
    top: 80px;
}

.mini-left-3 {
    width: 13px;
    height: 13px;
    background-color: #c6cdd7;
    top: 215px;
    left: 20px;
}

.mini-left-4 {
    width: 15px;
    height: 15px;
    background-color: #4e8acc;
    right: 0;
    top: 80px;
}

.mini-left-5 {
    width: 17px;
    height: 17px;
    background-color: #c6cdd7;
    top: 215px;
    right: 20px;
}

.mini-right-1 {
    width: 15px;
    height: 15px;
    background-color: #94bbe4;
    top: 5px;
    left: 170px;
}

.mini-right-2 {
    width: 13px;
    height: 13px;
    background-color: #c6cdd7;
    top: 90px;
    right: -5px;
}

.mini-right-3 {
    width: 20px;
    height: 20px;
    background-color: #4e8acc;
    top: 90px;
    left: -10px;
}

.mini-right-4 {
    width: 10px;
    height: 10px;
    background-color: #c6cdd7;
    bottom: 0;
    right: 80px;
}

.mini-bottom-1 {
    width: 24px;
    height: 24px;
    background-color: #7ec3e0;
    bottom: 53px;
    right: 0px;
}

.mini-bottom-2 {
    width: 15px;
    height: 15px;
    background-color: #c6cdd7;
    top: 0;
    right: 65px;
}

.mini-bottom-3 {
    width: 18px;
    height: 18px;
    background-color: #94bbe4;
    bottom: 60px;
    left: 0;
}


@keyframes rotate-left {
    0% {
        transform: rotate(360deg)
    }

    100% {
        transform: rotate(0deg)
    }
}

@keyframes rotate-right {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}