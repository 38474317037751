@font-face {
    font-family: "Hurme-Geometric-Sans-No.4";
    src: url("../assets/fonts/Hurme-Geometric-Sans-4-W00-Bold.woff2") format("woff2"),
        url("../assets/fonts/Hurme-Geometric-Sans-4-W00-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Hurme-Geometric-Sans-No.4";
    src: url("../assets/fonts/Hurme-Geometric-Sans-4-W00-SmBd.woff2") format("woff2"),
        url("../assets/fonts/Hurme-Geometric-Sans-4-W00-SmBd.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Hurme-Geometric-Sans-No.4";
    src: url("../assets/fonts/Hurme-Geometric-Sans-4-W00-Rg.woff2") format("woff2"),
        url("../assets/fonts/Hurme-Geometric-Sans-4-W00-Rg.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Hurme-Geometric-Sans-No.4";
    src: url("../assets/fonts/Hurme-Geometric-Sans-4-W00-Lt.woff2") format("woff2"),
        url("../assets/fonts/Hurme-Geometric-Sans-4-W00-Lt.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    src: url("../assets/fonts/Rubik-Bold.woff2") format("woff2"),
        url("../assets/fonts/Rubik-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    src: url("../assets/fonts/Rubik-Medium.woff2") format("woff2"),
        url("../assets/fonts/Rubik-Bold.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    src: url("../assets/fonts/Rubik-Regular.woff2") format("woff2"),
        url("../assets/fonts/Rubik-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    src: url("../assets/fonts/Rubik-Light.woff2") format("woff2"),
        url("../assets/fonts/Rubik-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}