.wrapper {
    margin-top: 15px;
    padding-bottom: 100px;
}

.chat-window {
    display: flex;
    flex-direction: column-reverse;
    margin: 0 auto;
    background-color: #fff;
    padding: 0 .5rem;
    overflow: auto;
}

.chat-messages {
    flex: 1;
    padding: 16px;
    overflow-y: auto;
}

.chatFieldWrapper {
    margin-top: 1rem;
    position: fixed;
    bottom: 5px;
    z-index: 1;
    background-color: rgba(#1890ff, .75);
    width: 100% !important;
    margin: 1rem auto;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 8px;
}