.printContainer {
  padding: 0.5rem;
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  color: black;

  p {
    font-size: 14px;
    font-family: "Rubik", sans-serif;

    &.small {
      font-size: 12px;
    }
  }
}

.itemBorder {
  margin-top: 4px;
  margin-bottom: 4px;
  border-bottom: 1px dashed #71717a;
}

.smallText {
  p {
    font-size: 12px;
  }
}

.logo {
  filter: grayscale(100%);
}