.brand {
    display: flex;
    justify-content: center;
}

.links {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .icon {
        margin: 0 0 .5rem 0;
        margin-right: 0 !important;
    }

    .label {
        font-size: .75rem;
    }
}

.search {
    width: 250px;
    margin-right: 1rem;
}

.logo {
    width: 20px;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    margin: .5rem auto;
}

.iconWrap {
    width: 24px !important;
    height: 24px !important;
}

.icon {
    cursor: pointer;
    font-size: .7rem;
    margin-bottom: 0 !important;
}