.livePlayer {
  height: 400px;
  width: 800px;
  border: 1px solid #646cff;
  margin: 10px 0 30px 0;
  border-radius: 8px;
  margin-bottom: 0px
}

.recordedPlayer video {
  height: 400px;
  width: 800px;
}
.videoButton{
  width: 50px !important;
  height: 50px;
  border-radius: 50%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}